import React from 'react'
import Layout from '../../components/layout'
import {  graphql } from 'gatsby'

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import logo from '../../images/logo.png'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    margin: '20px',

    '&:hover': {
      boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.12)',
    },
  },

  cardActionArea: {
    height: '100%',
    padding: '20px',
    '&:hover $focusHighlight': {
      opacity: 0,
    },
  },

  cardContainer: {
    flexGrow: 1,
    '&:hover': {
      zIndex: 1,
    },
  },
}))

const BlogItem = props => {
  const classes = useStyles()
  return (
    <Card elevation="0" className={classes.card}>
      <CardActionArea
        href={props.path}
        classes={{
          root: classes.cardActionArea,
          focusHighlight: {},
        }}
      >
        <CardMedia
          component="img"
          alt={props.title}
          height="140"
          image={props.img ? props.img : logo}
          title={props.title}
          style={props.img ? {} : { backgroundColor: '#3f51b5' }}
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            {props.title}
          </Typography>
          <Typography variant="body2" component="p">
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

const BlogPage = ({ data }) => {
  const classes = useStyles()
  return (
    <Layout>
      <Container
        maxWidth={'lg'}
        style={{
          padding: '64px 0',
        }}
      >
        <Grid container>
          {data.allMarkdownRemark.edges.map(post => (
            <Grid item sm={6} xs={12} className={classes.cardContainer}>
              <BlogItem
                key={post.node.id}
                title={post.node.frontmatter.title}
                description={post.node.frontmatter.description}
                path={post.node.frontmatter.path}
                img={
                  post.node.frontmatter.hero != null
                    ? post.node.frontmatter.hero.childImageSharp.fluid.src
                    : null
                }
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogIndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { path: { ne: null }, description: { ne: null } } }

      limit: 1000
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date
            author
            description
            which
            hero {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPage
